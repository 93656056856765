/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

export enum GGCCLicenseType {
  commercial = 'ENT',
  developer = 'EVL',
  noLicense = 'NO_LICENSE',
}

export type GGCCLicenseInfo = {
  maxNodes: number;
  currentNodes: number;
  maxCpuCores: number;
  currentCpuCores: number;
} & (
  | {
      licenseType: GGCCLicenseType.developer | GGCCLicenseType.noLicense;
      expirationDateIsoString: null;
      daysRemaining: null;
      registeredTo: null;
    }
  | {
      licenseType: GGCCLicenseType.commercial;
      expirationDateIsoString: string;
      daysRemaining: number;
      registeredTo: string;
    }
);

export interface GGCCLicenseStatus {
  licenseType: GGCCLicenseType;
  errorMessage: string | null;
}
