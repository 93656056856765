/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketConnection } from '@app/core/services';
import { GGCCLicenseStatus } from '@shared/types/license';
import { Observable } from 'rxjs';
import { GGCCLicenseInfo, GGCCLicenseText } from './types';

@Injectable({ providedIn: 'root' })
export class GGCCLicenseApi {
  constructor(
    private ws: WebSocketConnection,
    private http: HttpClient,
  ) {}

  licenseInfo(): Observable<GGCCLicenseInfo> {
    return this.ws.getTopic<GGCCLicenseInfo>(`/topic/admin/license-info`);
  }
  updateLicense(license: GGCCLicenseText): Observable<void> {
    return this.http.post<void>(`/api/v1/admin/license`, { text: license });
  }
  removeLicense(): Observable<void> {
    return this.http.delete<void>(`/api/v1/admin/license`);
  }
  licenseStatus(): Observable<GGCCLicenseStatus> {
    return this.ws.getTopic<GGCCLicenseStatus>(`/topic/license-status`);
  }
}
